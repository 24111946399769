import LoaderComponent from '@/ui/components/shared/LoaderComponent'
// eslint-disable-next-line import/no-named-as-default
import MapBoxHomepage from '@/ui/components/footer/MapBoxHomepage'
// eslint-disable-next-line import/no-named-as-default
import MapBoxBranch from '@/ui/components/footer/MapBoxBranch'

export const CustomMap = ({ isOnBranch, regions, coordinates }) => {
	if (isOnBranch && !regions) return <LoaderComponent />
	if (!isOnBranch && !coordinates) return <LoaderComponent />

	return (
		<div className={'mt-7 lg:mt-0 xs:rounded-xl overflow-hidden'}>
			{isOnBranch ? <MapBoxHomepage regions={regions} /> : <MapBoxBranch coordinates={coordinates} />}
		</div>
	)
}
export default CustomMap
