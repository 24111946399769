import { useEffect, useRef } from 'react'
import mapboxGl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export const MapBoxHomepage = ({ regions }) => {
	const mapContainerRef = useRef(null)

	const calculateBounds = markers => {
		const bounds = new mapboxGl.LngLatBounds()
		markers.forEach(marker => {
			bounds.extend([marker.lon, marker.lat])
		})
		return bounds
	}

	useEffect(() => {
		const mapConfig = {
			mapsEndpointBase: 'https://osm.speedlo.cloud',
			mapsStyleUrl: 'https://osm.speedlo.cloud/styles/klokantech-basic/style.json',
			mapsEndpoint: 'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png'
		}

		mapboxGl.accessToken = 'no-token'
		mapboxGl.baseApiUrl = mapConfig.mapsEndpointBase

		const markersData = regions.flatMap(region =>
			region.branches.map(branch => ({
				lat: branch.lat,
				lon: branch.long,
				name: branch.city
			}))
		)

		const map = new mapboxGl.Map({
			container: mapContainerRef.current,
			style: mapConfig.mapsStyleUrl,
			scrollZoom: false,
			touchZoomRotate: false
		})

		const bounds = calculateBounds(markersData)

		map.fitBounds(bounds, {
			padding: 50,
			maxZoom: 12
		})

		markersData.forEach(({ lat, lon, name }) => {
			const popup = new mapboxGl.Popup({ offset: 25 }).setText(name)

			new mapboxGl.Marker().setLngLat([lon, lat]).setPopup(popup).addTo(map)
		})

		return () => map.remove()
	}, [regions])

	return <div ref={mapContainerRef} style={{ width: '484px', height: '300px' }} />
}

export default MapBoxHomepage
