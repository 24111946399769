import { useCallback, useEffect, useState } from 'react'
import Navbar from '@/ui/components/menu/Navbar'
import TitleBlock from '@/ui/components/arrows/TitleBlock'
import FooterInformationBlock, { formatPhoneNumber } from '@/ui/components/footer/FooterInformationBlock'
import { CustomMap } from '@/ui/components/footer/CustomMap'
import FooterSocials from '@/ui/icons/FooterSocials'
import { FooterTypeEnum } from '@/constants/constants'
import { AxiosClient } from '@/axios/axios.client'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { OpeningHours } from '@/ui/components/footer/OpeningHours'
import { branchDetailQuery } from '@/graphql/query/branchDetailQuery'
import {
	branchClosedOrderOnLaterTimeSelector,
	orderDataSelector,
	orderDeliveryTypeSelector,
	orderIdSelector,
	scrollSelector
} from '@/selectors/selectors'
import BranchClosedModal from '@/ui/components/modal/BranchClosedModal'
import { setOrderData, setOrderLaterClosedBranch } from '@/redux/actions/orderAction'
import { performScroll, setupScrollElement } from '@/functions/ui/scroller'
import VOP from '@/ui/components/footer/VOP'
import CartMenu from '@/ui/components/menu/CartMenu'
import { createOrder } from '@/functions/orderControl/orderControl'
import { captureException } from '@sentry/nextjs'

const Layout = ({
	children,
	showCity = true,
	customData,
	visibleCart = true,
	footerType = FooterTypeEnum.HP_BRANCH,
	logoClick
}) => {
	let router = useRouter()
	let dispatch = useDispatch()

	const orderId = useSelector(orderIdSelector)
	const orderData = useSelector(orderDataSelector)
	const orderDeliveryType = useSelector(orderDeliveryTypeSelector)
	const branchClosedOrderOnLaterTime = useSelector(branchClosedOrderOnLaterTimeSelector)
	const scrollToElement = useSelector(scrollSelector)

	const [layoutData, setLayoutData] = useState({
		isClosedBranch: false,
		data: null,
		speedloData: null
	})
	const [closedModal, setClosedModal] = useState(false)
	const [width, setWidth] = useState(null)

	const isBrandHomepage = footerType === FooterTypeEnum.HP_BRAND
	const addFooterPadding = calculateFooterPadding()
	const isMobile = width < 1024

	useEffect(() => {
		if (window) setWidth(window.innerWidth)
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

	/*
	 * Set correct orderData
	 * */
	useEffect(() => {
		if (layoutData.data) {
			const data = layoutData.data
			let currentOrderData = orderData

			dispatch(
				setOrderData({
					id: data.branch.speedloId,
					city: data.branch.city,
					url: data.branch.slug,
					gps: currentOrderData && currentOrderData.gps,
					formattedAddress: currentOrderData && currentOrderData.formattedAddress,
					unFormattedAddress: data.unFormattedAddress,
					zoneId: currentOrderData && currentOrderData.zoneId,
					zoneInterval: currentOrderData && currentOrderData.zoneInterval
				})
			)
		}
	}, [dispatch, layoutData.data])

	/**
	 * Function will scroll to element after certain amount of time.
	 * It is called every time "scrollToElement" gets updated
	 * It is called only when such element exists
	 */
	useEffect(() => {
		if (scrollToElement) {
			setTimeout(() => {
				performScroll(scrollToElement)
				setupScrollElement(dispatch, null)
			}, 250)
		}
	}, [dispatch, scrollToElement])

	/*
	 * Load data about branch from speedlo and our BE with
	 * Comes also with attribute if branch should be closed
	 * */
	useEffect(() => {
		if (router.query.branch) {
			let branch = router.query.branch
			if (!branch.includes('.')) {
				if (!customData) {
					AxiosClient.get(`/v1/web/hp/${branch}/load`)
						.then(nodeResult => {
							if (nodeResult.data) loadRemainingData(nodeResult.data.data)
						})
						.catch(err => {
							console.error(err)
						})
				} else {
					loadRemainingData(customData)
				}
			}
		}
		return () => {
			setLayoutData({
				data: null,
				speedloData: null,
				isClosedBranch: false
			})
		}
	}, [router.query.branch])

	function loadRemainingData(data) {
		const variables = {
			branchId: data.branch.speedloId
		}
		let branchIsClosed = data.isBranchedClosed
		if (!branchIsClosed && branchClosedOrderOnLaterTime) dispatch(setOrderLaterClosedBranch(false))
		if (branchClosedOrderOnLaterTime) branchIsClosed = false
		callGraphQlClient(branchDetailQuery, variables, dispatch)
			.then(speedloResult => {
				setLayoutData({
					isClosedBranch: branchIsClosed,
					data: data,
					speedloData: speedloResult
				})
				setClosedModal(branchIsClosed)
			})
			.catch(err => {
				// captureException(err);
				console.error(err)
			})
	}

	/**
	 * Function which creates new order if none is present
	 * */
	useEffect(() => {
		createOrder(orderId, orderDeliveryType, orderData, dispatch)
			.then(() => {})
			.catch(err => {
				captureException(err)
			})
	}, [dispatch, orderData, orderDeliveryType, orderId])

	function handleWindowSizeChange() {
		setWidth(window.innerWidth)
	}

	const hideBranchClosedModal = useCallback(() => {
		setClosedModal(false)
	}, [])

	function calculateFooterPadding() {
		if (router.asPath.includes('detail')) return true
		if (isMobile) {
			return router.asPath.includes('kosik') | router.asPath.includes('placeni')
		}
		return false
	}

	return (
		<div id={'topElement'} className={'relative'}>
			<Navbar isMobile={isMobile} city={showCity} isCartVisible={visibleCart} logoClick={logoClick} />
			{visibleCart && <CartMenu mobile={true} />}
			{layoutData.isClosedBranch && closedModal && (
				<BranchClosedModal
					layoutData={layoutData}
					shouldRedirect={!router.asPath.includes('placeni')}
					closeModal={hideBranchClosedModal}
				/>
			)}
			<main>
				{children}
				{/*<ReportProblem />*/}
			</main>
			<div id="footer" className={`bg-turbo-background ${addFooterPadding && 'pb-12'}`}>
				<TitleBlock title={'Kontaktujte nás'} />
				<div className={'mx-auto pb-10 xs:px-5 lg:mt-12 lg:container xl:pb-20'}>
					<div className={'lg:grid lg:grid-cols-2 lg:gap-x-4 mt-8'}>
						<div className={'hidden lg:inline '}>
							<CustomMap
								regions={customData && customData.regions}
								coordinates={{
									lat: layoutData.data && layoutData.data.branch.lat,
									long: layoutData.data && layoutData.data.branch.long
								}}
								isOnBranch={isBrandHomepage}
							/>
						</div>
						{isBrandHomepage ? (
							<FooterInformationBlock
								phoneNumber={'+420 776 222 172'}
								location={'Kaprova 42/14, Praha 1'}
								email={'info@turbopizza.cz'}
							/>
						) : (
							<>
								{layoutData && layoutData.speedloData && layoutData.speedloData.companyBranch && (
									<FooterInformationBlock
										location={
											layoutData.speedloData.companyBranch.address.location.city +
											' ' +
											layoutData.speedloData.companyBranch.address.location.postCode +
											', ' +
											layoutData.speedloData.companyBranch.address.location.street +
											' ' +
											layoutData.speedloData.companyBranch.address.location.houseNumber
										}
										phoneNumber={formatPhoneNumber(layoutData.speedloData.companyBranch.phone)}
										email={
											layoutData.speedloData.companyBranch.address.emails &&
											layoutData.speedloData.companyBranch.address.emails[0].email
										}
										payments={layoutData.speedloData.companyBranch.paymentMethods}
									/>
								)}
							</>
						)}
						<div className={'visible lg:hidden'}>
							<CustomMap
								regions={customData && customData.regions}
								coordinates={{
									lat: layoutData.data && layoutData.data.branch.lat,
									long: layoutData.data && layoutData.data.branch.long
								}}
								isOnBranch={isBrandHomepage}
							/>
						</div>
					</div>
					{!isBrandHomepage && (
						<OpeningHours
							data={layoutData && layoutData.data}
							speedloData={layoutData && layoutData.speedloData}
						/>
					)}
					<VOP />
					<FooterSocials />
					{process.env.NEXT_PUBLIC_BITBUCKET_VERSION && (
						<div className={'text-center text-xs mt-5'}>{process.env.NEXT_PUBLIC_BITBUCKET_VERSION}</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Layout
