import { useEffect, useRef } from 'react'
import mapboxGl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export const MapBoxBranch = ({ coordinates }) => {
	const mapContainerRef = useRef(null)

	useEffect(() => {
		const mapConfig = {
			mapsEndpointBase: 'https://osm.speedlo.cloud',
			mapsStyleUrl: 'https://osm.speedlo.cloud/styles/klokantech-basic/style.json',
			mapsEndpoint: 'https://osm.speedlo.cloud/styles/klokantech-basic/{z}/{x}/{y}.png'
		}

		mapboxGl.accessToken = 'no-token'
		mapboxGl.baseApiUrl = mapConfig.mapsEndpointBase

		const map = new mapboxGl.Map({
			container: mapContainerRef.current,
			style: mapConfig.mapsStyleUrl,
			center: [coordinates.long, coordinates.lat],
			zoom: 13,
			scrollZoom: false,
			touchZoomRotate: false
		})

		const popup = new mapboxGl.Popup({ offset: 25 }).setText(name)

		new mapboxGl.Marker().setLngLat([coordinates.long, coordinates.lat]).setPopup(popup).addTo(map)

		return () => map.remove()
	}, [coordinates])

	return <div ref={mapContainerRef} style={{ width: '484px', height: '300px' }} />
}

export default MapBoxBranch
